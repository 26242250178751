import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert,
    Modal
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { BsPen } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";


import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { TiPen } from "react-icons/ti";
import { MdAddBusiness } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { BsCheckAll } from "react-icons/bs";





registerLocale('pt-br', ptBR)

interface Props {
  eventCode: any,
  eventDataTransformed: any,
  eventTitle: any,
  eventStatus: any
}

export function EventSnippetDetails({
  eventCode,
  eventDataTransformed,
  eventTitle,
  eventStatus
}: Props) {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }


    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };





    const dateFormatter = (dateTime:string)=>{

      const options:any = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
  
      const oldDate = new Date(dateTime)
      return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
    }


  const [disableSign, setDisableSign] = useState(false)




    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)





    const [currentEventEdit, setCurrentEventEdit] = useState<any>({})
    const [eventDue, setEventDue] = useState<any>()

    
      const editEvent = (data: any) => {

        if(eventDue){
          data.startDateTime = new Date(eventDue).toISOString()
        }

        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventCode}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
      }

      const excludeEvent = (eventId: any) => {



        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
              status: 'deleted',
              isActive: false
            })
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
      }
    

      const activeEvent = (eventId: any) => {



        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
              status: 'active'
            })
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
      }
    
      
    

    return (
<div className="event-action-top">
  

<ul className="event-action-list">
    <li onClick={() => {handleShow()}} className='btn btn-default'>
      <i className='custom-icon'>
        <TiPen/>
      </i>
      Editar
    </li>
    <li onClick={() => {
  
  if(confirm('Tem certeza?')){
    activeEvent(eventCode)
  }

}} className='btn btn-default'>
      <i className='custom-icon'>
        <BsCheckAll/>
      </i>
      Ativar
    </li>
    <li onClick={() => {
  
  if(confirm('Tem certeza?')){
    excludeEvent(eventCode)
  }

}} className='btn btn-default'>
      <i className='custom-icon'>
        <FaTimes/>
      </i>
      Excluir
    </li>
  </ul>

  {/* <div onClick={() => window.location.href = `/events/providers/${eventCode}`} className="square-button call-buttom rounded-sm">
          <i className='custom-icon'>
            <AiOutlineEye/>
          </i>
          <span>Visualizar</span>
      </div> */}
    <div className="event-action-top-info">
      <div className="square-button rounded-sm">
        <span className="event-info-month">
        {eventDataTransformed.dataTransformMonth}
        </span>
        <span className="event-info-date">
          {eventDataTransformed.dataTransformDay}
        </span>
      </div>
      <div className="square-button event-title rounded-sm">
        <span className="event-info-time">
        {eventDataTransformed.dataTransformTime}
        </span>
        <span className="event-info-title">
          {eventTitle}
        </span>
  
  </div>
  </div>
  
  <Modal show={show} onHide={handleClose}>

<Modal.Body>
<Form onSubmit={handleSubmit(editEvent)}>

            <Form.Label>Jogo:</Form.Label>
            <Form.Control placeholder="Jogo: "
            {...register('name')}
            defaultValue={eventTitle}
            className='mb-3'
            />

            <Form.Label>Data / hora</Form.Label>
            <DatePicker
                className="form-control"
                locale="pt-br"
                showTimeInput={true}
                timeInputLabel='Hora'
                dateFormat="dd/MM/yyyy : HH:mm"
                selected={eventDue}
                onChange={(date: Date) => setEventDue(date)}
            />
            {errors.dueDate && (
                <span className="label-error">
                    Você precisa informar uma data válida
                </span>
            )}


            <Form.Label>Status</Form.Label>
            <Form.Select
              {...register('status')}
              defaultValue={eventStatus}
              className='mb-3'
            >
              <option value={'active'}>Ativo</option>
              <option value={'idle'}>Inativo</option>
            </Form.Select>
            {errors.email && (
              <span className="label-error">
              </span>
            )}

            <Form.Label>Logo Time Visitante:</Form.Label>
            <Form.Control
              type="file"
              placeholder="Logo"
              className='mb-3'
            />
   
          <Form.Group>
            <Button
              disabled={disableSign}
              type="submit"
              className="mb-0 w-100"
              onClick={() => clearErrors()}
            >
              Salvar
            </Button>
          </Form.Group>

  </Form>

</Modal.Body>

  </Modal>


  </div>  
    )
}
