import React, { useCallback, useEffect, useState, useContext } from 'react'

import './App.sass'
import './index.scss'

import SideBar from './shared/sidebar'
import Monitoring from './views/Monitoring/Monitoring'

import { BrowserRouter, Route, Link, Routes } from 'react-router-dom'
import Login from './views/Auth/Login'
import VisitorsList from './views/VisitorList/Visitors'
import CreateUser from './views/Auth/CreateUser'
import EditUser from './views/Auth/EditUser'
import Approvers from './Approvers/Approvers'
import GameReports from './views/Reports/GameReports'
import Reports from './views/Reports/Reports'

import { AuthProvider } from './Context/AuthContext'

import { Context } from './Context/AuthContext'

import PropTypes from 'prop-types';
import Organization from './views/Organizations/Organizations'
import Events from './views/Events/Events'
import Providers from './views/Providers/Providers'
import ShowEvent from './views/Events/showEvent'
import ShowEventProvider from './views/Events/showEventProvider'
import DashProvider from './views/Dashboard/DashboardProvider'
import DashCoordinator from './views/Dashboard/DashboardCoordinator'
import CurrentEvent from './views/Events/CurrentEvent'
import DashLocal from './views/Dashboard/DashboardLocal'
import Dashboard from './views/Dashboard/Dashboard'
import AddProviderToEvent from './views/Events/addProviderToEvent'
import SupplierCurrentEvent from './views/Events/SupplierCurrentEvent'
import CreateAdminUser from './views/Auth/CreateAdminUser'
import DashboardFanBase from './views/Dashboard/DashboardFanbase'
import ShowSingleEvent from './views/Events/showSingleEvent'
import OperationEvent from './views/Events/operationEvent'
import HeaderLine from './shared/header'
import BlockUserlist from './views/Auth/BlockUserlist'
import { EventList } from './views/Events/eventList'
import EventsSupplier from './views/Events/EventsSupplier'

CustomRoutes.propTypes = {
  isPrivate: PropTypes.bool
};

function CustomRoutes({ isPrivate, ...rest }) {
  const { authenticated } = useContext(Context)
  if (isPrivate && !authenticated) {
    return <> {rest} </>
  }

  return <> {rest} </>
}

function App() {
  return (
    <>
      {/* <DashDefault /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Dashboard />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/dashboard/fanbase"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <DashboardFanBase />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/home"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <DashLocal />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/dashboard/coordinator"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <DashCoordinator />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/dashboard/provider"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <DashProvider />
                  </div>
                </div></>
            }
          />
          <Route
            path="/dashboard/local"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Dashboard />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/organizations"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Organization />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/events"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Events />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/events/supplier"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <EventsSupplier />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/event/:eventId"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <CurrentEvent />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/my-events/:eventId"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <SupplierCurrentEvent />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/events/providers/:eventId"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <AddProviderToEvent />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/events/:eventId"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <OperationEvent />
                    {/* <ShowSingleEvent /> */}
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/events/operation/:eventId"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <OperationEvent />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/events/eventGroup/:groupId"
            element={
              <AuthProvider>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <EventList />
                    {/* <ShowSingleEvent /> */}
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/provider/events/:eventId/:supplierId"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <ShowEventProvider />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/providers"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Providers />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/monitoring"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Monitoring />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/reporting/:eventId"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Reports />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/visitors"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <VisitorsList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/user/create"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <CreateUser />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/user/admin"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <CreateAdminUser />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/user/block"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <BlockUserlist />
                  </div>
                </div>
              </AuthProvider>
            }
          />
          <Route
            path="/approvers"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Approvers />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/user/edit/:id"
            element={
              <AuthProvider>
                <HeaderLine/>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <EditUser />
                  </div>
                </div>
              </AuthProvider>
            }
          />
        </Routes>
      </BrowserRouter>
    </ >
  )
}

export default App
