import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import avatar1 from '../../assets/images/user/avatar-1.jpg'

import tombrasil from '../../assets/images/logos-tom-brasil.jpg'
import alma from '../../assets/images/logo-alma.png'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'
import { EventCreate } from './createEvent'
import { AddProvider } from './addProvider'
import { MultipleUserCreate } from '../components/multipleUserCreate'
import { SingleProvider } from '../Providers/singleProvider'

function CurrentEvent() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    const [pridedUserList, setPridedUserList] = useState<any[]>([])


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const [userList, setUserList] = useState<any[]>([
        {
            "name": "SecCutiry",
            "access_code": null,
            "token": "$2a$10$r8Ah7cr4m1RAgwVAq8oT7.n5gOjqGxQlehvfITN9ZPWbKUkHsJdff",
            "externalId": "190",
            "hikId": "da85ecda9d0011eda8fc0242ac120002",
            "active": true,
            "userAvatar": '',
            coordinator: "Lucas Chaves",
            "biometricTotal": "150",
            "biometricLeft": "50",
            "syncStatus": [

            ]
        }
    ])


    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    // const getAllUsers = () => {
    //     setLoading(true)
    //     fetch(`${BASE.api.base_url}${BASE.api.find_allusers_with_biometry}`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: CONFIG.API_KEY
    //         },
    //         body: JSON.stringify({
    //             originCompany: 'da85ecda-9d00-11ed-a8fc-0242ac120002'
    //         })
    //     })
    //         .then((resg) => resg.json())
    //         .then((response) => {
    //             setLoading(false)
    //             setUserList(response)

    //             const enabledCount = response.filter(item => item.biometric).length
    //             setTotalUsers({
    //                 withBiometry: enabledCount,
    //                 withoutBiometry: response.length - enabledCount
    //             })


    //         })
    //         .catch((error) => {
    //             setLoading(false)
    //             console.log(error)
    //         })
    // }


    const getAllUsers = () => {
        setLoading(true)
        fetch(`${BASE.api.base_url}${BASE.api.find_allusers_with_biometry}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                originCompany: 'da85ecda-9d00-11ed-a8fc-0242ac120002'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response)

                const enabledCount = response.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    useEffect(() => {
        getAllUsers()
    }, [])

    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const resetForm = () => {
        setDisableSign(false)
        reset()
    }


    useEffect(() => {
        // getAllUsers()
    }, [])


    const [showToast, setShowToast] = useState(false);
    const sendEmail = (data) => {
        setLoading(true)


        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.firstName,
                email: data.email,
                token: data.token,
                subject: 'Cadastro para acesso Fonte Nova',
                template: 'fontenova-credenciados'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setShow(true)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    return (
        <>
            <Row>
                <Col md={8} xl={9}>
                    <Card className="Recent-Users">
                        <Card.Header>
                            <Card.Title as="h5">
                                <i className={`fa fa-circle text-c-green f-12 m-r-15`} />
                                THIAGUINHO</Card.Title>
                            <h6 className="text-muted biometry-status m-t-10">
                                22/10/2023 19:00
                            </h6>

                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            <Table responsive hover className='display-table'>
                                <tbody>

                                    {userList &&
                                        userList.map((user: any, index) => (

                                            <tr key={index} className="unread">

                                                <td>
                                                    <h6 className="mb-1">{user.name}</h6>
                                                </td>

                                                <td>
                                                    <p className='text-muted  m-t-10'> contato: Mariela Savello (masavelo@seccurity.com | 11 9922.25551)</p>
                                                </td>



                                                <td className='biometric-progress'>

                                                    {user.biometricTotal && userSyncProgress(user.biometricTotal, user.biometricLeft) < 100 ?

                                                        (userSyncProgress(user.biometricTotal, user.biometricLeft) < 0) ?
                                                            <ProgressBar variant="danger" label={'erro'} now={100} /> :
                                                            (<ProgressBar animated label={`${userSyncProgress(user.biometricTotal, user.biometricLeft)}%`} now={userSyncProgress(user.biometricTotal, user.biometricLeft)} />) :
                                                        userSyncProgress(user.biometricTotal, user.biometricLeft) > 0 ?
                                                            (
                                                                <ProgressBar variant="success" label={'completa'} now={100} />
                                                            ) : ''
                                                    }
                                                </td>





                                            </tr>


                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {/* 

                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <div className="row align-items-center justify-content-center card-active">
                                        <div className="col-6">
                                            <h6 className="text-center m-b-10 d-relative">
                                                <i className="feather icon-chevrons-up f-20 m-r-15 text-c-blue" />
                                                <span className="text-muted m-r-5">Check-in:</span> 1
                                            </h6>
                                          <ProgressBar variant="danger" label={'erro'} now={100} /> 
                                        </div>
                                        <div className="col-6">
                                            <h6 className="text-center  m-b-10">
                                                <span className="text-muted m-r-5">Check-out:</span> 0
                                                <i className="feather icon-chevrons-down f-20 m-l-15 text-c-red" />
                                            </h6>
                                             <ProgressBar variant="danger" label={'erro'} now={100} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}


                    <Row>

                        <Col md={6}>
                            <Card>
                                <Card.Body className="border-bottom">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-auto">
                                            <i className="feather icon-alert-circle f-30 text-c-yellow" />
                                        </div>
                                        <div className="col">
                                            <h3 className="f-w-300">2</h3>
                                            <span className="d-block text-uppercase">Biometrias Incompletas</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card>
                                <Card.Body className="border-bottom">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-auto">
                                            <i className="feather icon-users f-30 text-c-green" />
                                        </div>
                                        <div className="col">
                                            <h3 className="f-w-300">3</h3>
                                            <span className="d-block text-uppercase">Pessoas Cadastradas</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Card className="Recent-Users">
                        <Card.Header>


                            <Form>
                                <Row className="align-items-center">
                                    <Col sm={12} md={2}>
                                        <Card.Title as="h5">Pessoas</Card.Title>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Label htmlFor="inlineFormInputName" visuallyHidden>
                                            Evento
                                        </Form.Label>
                                        <Form.Control id="inlineFormInputName" placeholder="Nome ou Documento" />
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                                            Data
                                        </Form.Label>
                                        <select
                                            className="form-control"
                                        >
                                            <option selected value="Matarazzo">Função</option>
                                            <option value="Palestra">Segurança</option>
                                            <option value="Conveniencia">Cattering</option>
                                            <option value="Estacionamento">OnBoarding</option>
                                            <option value="Operacional">Operacional</option>
                                        </select>
                                    </Col>
                                    <Col sm={12} md={2}>
                                        <select
                                            className="form-control"
                                        >
                                            <option selected value="Matarazzo">Biometria : Todos</option>
                                            <option value="Palestra">Sem Biometria</option>
                                            <option value="Conveniencia">Com Biometria</option>

                                        </select>
                                    </Col>



                                    <Col sm={12} md={3} lg={2} >
                                        <Button className='w-100' type="submit">Pesquisar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Checkin</th>
                                        <th>Foto</th>
                                        <th>Nome</th>
                                        <th>Função</th>
                                        <th>Biometria</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {pridedUserList &&
                                        pridedUserList.map((user: any, index) => (

                                            <tr key={index} className="unread">
                                                <td>
                                                    {/* onClick={() => { handleShowConfirm() }} */}
                                                    <h6 className="text-muted biometry-status m-t-10">
                                                        {index === 0 ? `22/10/2023 13:11` : ''}
                                                        <i className={`fa fa-circle text-c-${user.biometric ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>

                                                    {/* {index === 0 ? `` : (
                                                        <Link to="#" onClick={() => { handleShowConfirm() }} className="link-coordinator text-c-yellow m-l-15">
                                                            bloquear
                                                        </Link>
                                                    )} */}

                                                </td>
                                                <td>
                                                    <img className="rounded-circle" style={{ width: '40px' }} src={
                                                        user.userAvatar
                                                            ? `data:image/png;base64,${user.userAvatar}`
                                                            : avatar1
                                                    } alt="activity-user" />
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{user.name}</h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">Segurança</h6>
                                                </td>
                                                <td>
                                                    <h6 className="text-muted biometry-status m-t-10">
                                                        biometria
                                                        <i className={`fa fa-circle text-c-${user.biometric ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>
                                                </td>
                                                <td>
                                                    <Link to="#" onClick={() => sendEmail(user)} className="label theme-bg2 text-white f-12">
                                                        Enviar e-mail
                                                    </Link>

                                                    <Link to="#" onClick={() => { handleShowConfirm() }} className="link-coordinator text-c-red m-l-15">
                                                        excluir
                                                    </Link>

                                                </td>
                                            </tr>

                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
                <Col md={4} xl={3}>
                    <MultipleUserCreate />
                    <SingleProvider />
                </Col>

            </Row>





            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Local</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('email')}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Data/Hora</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Atualizar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
                    <Toast.Body>E-Mail enviado</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default CurrentEvent
