import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert,
    ToastContainer,
    Toast
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { Link, useParams } from 'react-router-dom';

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function SingleCreateUser() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [showProviderList, setShowProviderList] = useState(false)
    

    const [disableSign, setDisableSign] = useState(false)

    const [hasSearchUser, setHasSearchUser] = useState(false)

    const [supplierId, setSupplier] = useState(false)

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })


    const [loading, setLoading] = useState(false)
    const [supplierList, setSupplierList] = useState<any[]>([])


    const [providerList, setProviderList] = useState<any[]>([
        {
            "name": "SecCutiry",
            "access_code": null,
            "token": "$2a$10$r8Ah7cr4m1RAgwVAq8oT7.n5gOjqGxQlehvfITN9ZPWbKUkHsJdff",
            "externalId": "190",
            "hikId": "da85ecda9d0011eda8fc0242ac120002",
            "active": true,
            coordinator: "Lucas Chaves",
            "syncStatus": [

            ]
        },
        {
            "name": "Clara Gourmet",
            "access_code": null,
            "token": "$2a$10$szP4h29fFYRZEk3iZZ7iheEE3a4yynOq22iWQT3VP.UtIEvBICafO",
            "externalId": "777",
            "hikId": "963b3fbdf0df4dcd90e7ad699e08c282",
            "active": false,
            coordinator: null,
            "syncStatus": null
        },
        {
            "name": "Trehucchi Jogos",
            "access_code": null,
            "token": "$2a$10$pYZxO/Zswo6cISB3UIy3lu0jcseLl51aoD82p1vVt3FL5Q/vmglB6",
            "externalId": "888",
            "hikId": "00cbdb77a8fb402d97afe7628c8cad75",
            "active": false,
            coordinator: "Romulo Pires",
            "syncStatus": null
        }
    ])


    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }




    const quemta = () => {

        auth.onAuthStateChanged(() => {

            const user = auth.currentUser;
            if (user) {
                const uid = user.uid;
                console.log('minhapika', uid)
            } else {
                console.log('ta na milonga')
            }
        });

    }

    const [show, setShow] = useState(false);


    const onSubmit = (data: any) => {


      const currentTotem = sessionStorage.getItem('currentTotem') || 'na'

        const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')
        fetch(`${BASE.USER.url}${BASE.USER.user}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                supplierId,
                externalId: 'na',
                accessCode: 'na',
                function: data.function,
                sector: data.sector,
                firstName: temp_name[0],
                lastName: temp_name.slice(1).join(" "),
                gender: 'na_new',
                document: data.cpf,
                document_type: 'CPF',
                email: data.email,
                phoneNumber: data.phoneNumber,
                birthDate: '1999-1-1',
                eventId,
                manualInput: true,
                entrance: null
            })
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.status && response.status === 'success') {
                    manualCheckin(response.sup, supplierId)
                    reset()


                    MySwal.fire({
                      title: <h2>Aguarde um momento estamos gerando o QRCODE...</h2>,
                      html:  <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=https://fontenova.bepass.com.br/?token=${response.onboarding_url.split('token=')[1]}`}
                        className="qr-code"
                        alt="Fonte Nova"
                      />,
                      confirmButtonColor: '#7b7e8d',
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const { eventId } = useParams()

    const manualCheckin = (userIdCheckIn, supplierId) => {

        const currentTotem = sessionStorage.getItem('currentTotem') || 'na'

        fetch(`${BASE.api_user.base_url}${BASE.api_user.user_fixed_manual_checkin}/${userIdCheckIn}/${currentTotem}`, {
            // fetch(`${BASE.api_user.base_url}${BASE.api_user.user_manual_checkin}/${userIdCheckIn}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            }
        })
            .then((res) => res.json())
            .then((response) => {
                setShow(true)
            })
            .catch((error) => {
                console.log(error)
            })


    }

    const getAllSuppliers = () => {
        setLoading(true)

        fetch(`${BASE.EVENT.url}${BASE.EVENT.event_suppliers}/${eventId}`, {
          method: 'GET',
        })
          .then((resg) => resg.json())
          .then((response) => {
            setSupplierList(response.groups)
          })
          .catch((error) => {
            console.log(error)
          })

    }

    useEffect(() => {
        getAllSuppliers()
    }, [])

    const resetForm = () => {
        setDisableSign(false)
        reset()
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5">Cadastro Manual</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Col xs={12} className='mb-2'>
                                        <Form.Label>Nome Completo</Form.Label>
                                        <Form.Control
                                            placeholder="Nome"
                                            {...register('name')}
                                        />
                                        {errors.name && (
                                            <span className="label-error">
                                                Você precisa informar um CPF.
                                            </span>
                                        )}
                                    </Col>
                                    <Col xs={12} className='mb-2'>
                                        <Form.Label>CPF</Form.Label>
                                        <Form.Control
                                            placeholder="CPF"
                                            {...register('cpf')}
                                        />
                                        {errors.cpf && (
                                            <span className="label-error">
                                                Você precisa informar um CPF.
                                            </span>
                                        )}
                                    </Col>
                                    <Col xs={12} className='mb-2'>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            placeholder="Email"
                                            {...register('email')}
                                        />

                                    </Col>
                                    <Col xs={12} className='mb-2'>
                                        <Form.Label>Teletone</Form.Label>
                                        <Form.Control
                                            placeholder="Teletone"
                                            {...register('phoneNumber')}
                                        />

                                    </Col>
                                    <Col xs={12} className='mb-2'>
                                        <Form.Label>Função</Form.Label>
                                        <Form.Control
                                            placeholder="Função"
                                            {...register('function')}
                                        />

                                    </Col>
                                    <Col xs={12} className='mb-2'>
                                        <Form.Label>Setor</Form.Label>
                                        <Form.Control
                                            placeholder="Setor"
                                            {...register('sector')}
                                        />

                                    </Col>
                                    <Col xs={12} className='mb-2'>
                                        <Form.Label>Empresa</Form.Label>
                                        <select className='form-control' onChange={(item: any) => { const { value } = item.target; setSupplier(value) }}>
                                            {supplierList.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Button
                                                disabled={disableSign}
                                                type="submit"
                                                className="mb-0 w-100"
                                            >
                                                Cadastrar
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                            </Form>
                        </Col>
                    </Row>



                    <Table responsive>
                        <tbody>

                            {showProviderList &&
                                providerList.map((user: any, index) => (
                                    <>
                                        <tr key={index} className="unread">
                                            <td>
                                                <h6 className="mb-1">{user.name}</h6>
                                            </td>
                                            <td>
                                                <Link to="#" className="label theme-bg text-white f-12 m-l-15">
                                                    Adicionar
                                                </Link>
                                            </td>
                                        </tr>
                                    </>

                                ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='info'>
                    <Toast.Body>Prestador Cadastrado!</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}
