import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import './visitorCreate.sass'


import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";



registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom';

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function UserCreateAndBlock() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [startDate, setStartDate] = useState(new Date())

    const [hasToken, setHasToken] = useState('')

    const [disableSign, setDisableSign] = useState(false)

    const [newUser, setNewUser] = useState({
        login: '',
        password: '',
        set: false
    })

    function string_to_slug(str: any) {
        str = str.replace(/^\s+|\s+$/g, '') // trim
        str = str.toLowerCase()

        // remove accents, swap ñ for n, etc
        const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
        const to = 'aaaaeeeeiiiioooouuuunc------'
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '_') // collapse whitespace and replace by -
            .replace(/-+/g, '_') // collapse dashes

        return str
    }



    const signOut = async () => {
        try {
            await auth.signOut()
            return true
        } catch (error) {
            return false
        }
    };


    const quemta = () => {

        auth.onAuthStateChanged(() => {

            const user = auth.currentUser;
            if (user) {
                const uid = user.uid;
                console.log('minhapika', uid)
            } else {
                console.log('ta na milonga')
            }
        });

    }

    const onSubmit = (data: any) => {

        const companyId = sessionStorage.getItem('company')

        fetch(`${BASE.USER.url}${BASE.USER.blockedUsers}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                companyId: companyId,
                name: data.name,
                document: data.document,
                operator: sessionStorage.getItem('userId')
            })
        })
            .then((res) => {
              window.location.reload()

            })
            .catch((error) => {
                window.location.reload()
                console.log(error)
            })
    }

    const resetForm = () => {
        setNewUser({
            login: '',
            password: '',
            set: false
        })
        setHasToken('')
        setDisableSign(false)
        reset()
    }

    return (

      <div className="table-action-bar">
        <div className="single-form-creation">
          <h3>Informe os dados</h3>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="table-action-search">
              <label htmlFor="table-search" className="sr-only">Nome</label>
              <i>
                <PiMagnifyingGlassLight/>
              </i>
              <input type="text" {...register('name')} id="table-search-users" placeholder="Nome" />
            </div>

            <div className="table-action-search">
              <label htmlFor="table-search" className="sr-only">CPF</label>
              <i>
                <PiMagnifyingGlassLight/>
              </i>
              <input type="text" {...register('document')} id="table-search-users" placeholder="CPF" />
            </div>

            <div>
            <Button
                disabled={disableSign}
                type="submit"
                className="mb-0 w-100"
                onClick={() => clearErrors()}
            >
                Bloquear Usuário
            </Button>
            </div>
          </Form>
        </div>
      </div>

    )
}
